// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery')

// IMPORT
import barba from '@barba/core';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

gsap.registerPlugin(ScrollToPlugin);

document.addEventListener("turbolinks:load", () => {

  //barba page transitions
  barba.init({
    debug: true,
    preventRunning: true,

    transitions: [
      {
        name: 'slide',

        once() {

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .to(".loader", {y: '6vh', duration: 0.3, delay: 1})
              .to(".loader", {y: '-100vh', duration: 0.75, ease: "power3"})
              .fromTo("header .wrapper", {opacity: "0"}, {opacity: "1", delay: 1.8, duration: 0.6, ease: "power3"}, 0)
          })

        },

        leave(data) {

          // Scroll to top, wait for it to finish
          if (window.scrollY > 0) {
            return new Promise(resolve => {
              const timeline = gsap.timeline({
                onComplete() {
                  resolve()
                }
              })

              timeline
                .to(window, {duration: 0.5, scrollTo: 0, ease: "power1.inOut"})
            })
          }

        },

        enter(data) {

          console.log('enter')

          owlCarouselLoad()
          headerClose()

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.current.container.querySelector('.pageHeader'), {y: "0vh"}, {y: "20vh", duration: 0.3, ease: "power3"}, 0)
              .fromTo(data.next.container.querySelector('.pageHeader'), {opacity: "1"}, {opacity: "0", duration: 0}, 0)
              .fromTo(data.current.container, {opacity: "1"}, {opacity: "0", duration: 0.5, ease: "power3"}, 0)
              .fromTo('footer', {opacity: "1"}, {opacity: "0", duration: 0, delay: 0, ease: "power3"}, 0)
          })

        },

        beforeEnter() {},

        after(data) {

          console.log('after')

          return new Promise(resolve => {
            const timeline = gsap.timeline({
              onComplete() {
                resolve()
              }
            })

            timeline
              .fromTo(data.next.container.querySelector('.pageHeader'), {opacity: "0"}, {opacity: "1", duration: 0}, 0)
              .fromTo('.pageHeader', {y: "20vh"}, {y: "0vh", duration: 0.3, ease: "power3"}, 0)
              .fromTo(data.next.container, {opacity: "0"}, {opacity: "1", duration: 0.5, ease: "power3"}, 0)
              .fromTo('footer', {opacity: "0"}, {opacity: "1", duration: 0.3, delay: 0.3, ease: "power3"}, 0)
          })

        },

        beforeLeave({ current, next, trigger }) {},

      }
    ],

    views: [

      {
        namespace: "home",

        beforeEnter() {

        }
      },

      {
        namespace: "career",

        // Typeform embed
        afterEnter() {
          loadTypeformEmbed('https://form.typeform.com/to/ucLb1XXU');
          function loadTypeformEmbed(formUrl) {
            var embedElement = document.getElementById('typeformEmbed');
              typeformEmbed.makeWidget(embedElement, formUrl, {});
          }
        }
      },

      {
        namespace: "careers",

        // Typeform embed
        afterEnter() {
          loadTypeformEmbed('https://form.typeform.com/to/ucLb1XXU');
          function loadTypeformEmbed(formUrl) {
            var embedElement = document.getElementById('typeformEmbed2');
            typeformEmbed.makeWidget(embedElement, formUrl, {});
          }
        }
      }

    ]
  })

  // Owl carousel load
  owlCarouselLoad()
  function owlCarouselLoad() {
    $('.owl-carousel.image-carousel').owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive:{
        0: {
          items: 1
        }
      }
    })

    $('.owl-carousel.testimonial-carousel').owlCarousel({
      loop: true,
      margin: 60,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      responsive:{
        0: {
          items: 1
        }
      }
    })

    $('.owl-carousel.build-carousel').owlCarousel({
      loop: true,
      margin: 40,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      responsive:{
        0: {
          items: 1
        },
        600: {
          items: 2
        }
      }
    })
  }

  // Variable for header open
  var headerProtect = false

  // Header Menu Open
  $('header').mouseenter(headerOpen)

  function headerOpen() {
    // Check if header is protected
    if (headerProtect == false) {
      headerProtect = true
      // Slide open
      $('header .pages').slideDown(160)
      // Blur transition
      // $('main').css('filter', 'blur(15px)')
      // Change icon
      $('.menuOpen').css('display', 'none')
      $('.menuClose').css('display', 'block')
      setTimeout(function() {
        headerProtect = false
      }, 160);
    }
  }

  // Header Menu Close
  $('header').mouseleave(headerClose)

  function headerClose() {
    // Check if header is protected
    if (headerProtect == false) {
      headerProtect = true
      // Slide closed
      $('header .pages').slideUp(160)
      // Blur transition
      // $('main').css('filter', 'blur(0px)')
      // Change icon
      $('.menuOpen').css('display', 'block')
      $('.menuClose').css('display', 'none')
      setTimeout(function() {
        headerProtect = false
      }, 160);
    }
  }

  $('header').click(function(){
    console.log(window.innerWidth)
    if (window.innerWidth < 600) {
      console.log($('.pages').css('display'))
      if ($('.pages').css('display') == 'block') {
        headerClose()
      } else {
        headerOpen()
      }
    }
  })



})